.delete-icon {
  background: url("./icons/trash-alt-solid.svg") 16% 0% no-repeat;
  width: 20px;
  height: 20px;
  display: inline-block;
  margin: 5px;
  zoom: 109%;
}
.copy-icon {
  background: url("./icons/copy-solid.svg") 16% 0% no-repeat;
  width: 20px;
  height: 20px;
  display: inline-block;
  margin: 5px;
  zoom: 109%;
}
.view-icon {
  background: url("./icons/eye-solid.svg") 16% 0% no-repeat;
  width: 20px;
  height: 20px;
  display: inline-block;
  margin: 5px;
  zoom: 109%;
}
.cross-icon {
  background: url("./icons/circle-xmark-solid.svg") 16% 0% no-repeat;
  width: 25px;
  height: 25px;
  display: inline-block;
  margin: 5px;
  color: red;
  zoom: 109%;
}
.plus-icon {
  background: url("./icons/circle-plus-solid.svg") 16% 0% no-repeat;
  width: 25px;
  height: 25px;
  display: inline-block;
  color: blue;
  margin: 5px;
  zoom: 109%;
}
