.form-element {
  width: 336px;
  height: 189px;
  box-shadow: 0px 0px 20px 5px rgba(100, 100, 100, 0.1);
}
.form-element img:hover {
  cursor: pointer;
}
.form-element div:hover {
  cursor: pointer;
}
.form-element input {
  display: none;
}
.form-element img {
  width: 336px;
  height: 189px;
  border-radius: 3px;
  object-fit: cover;
}
.form-element div {
  position: relative;
  height: 40px;
  margin-top: -40px;
  background: rgba(0, 0, 0, 0.5);
  text-align: center;
  line-height: 40px;
  font-size: 13px;
  color: #f5f5f5;
  font-weight: 600;
}
.form-element div span {
  font-size: 15px;
}
.custom-container {
  margin: 3rem auto;
  padding: 2rem 4rem;
  background: #191a19;
  width: 40%;
}
@media screen and (max-width: 700px) {
  .custom-container {
    width: 100%;
    padding: 2rem;
  }
}
@media screen and (max-width: 400px) {
  .form-element {
    width: 269px;
    height: 151px;
  }
  .form-element img {
    width: 269px;
    height: 151px;
  }
}
td {
  word-break: break-all;
}
