td {
  word-break: break-all;
}

.playlist-heading {
  color: violet;
  font-size: 25px;
}

.no_playlist_msg {
  background-color: #191a19;
  border-radius: 5px;
  padding: 1rem;
}

.no_playlist_msg h4 {
  color: violet;
  font-size: 25px;
  text-align: center;
}

.table-container {
  width: 100%;
  overflow: auto;
  max-height: 73vh;
  margin-bottom: 1rem;
}

@media screen and (max-width: 1160px) {
  .table-container {
    width: 100%;
  }

  .playlist-card {
    width: 100%;
    height: 5rem;
  }

  .playlist-img {
    display: none;
  }
}

@media screen and (max-width: 360px) {
  .playlist-heading {
    font-size: 20px;
  }
}
